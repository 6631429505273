<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>All Reports</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm-2>
        <router-link to="/reports/weekly-pay/">
          <v-btn small block>Weekly Pay</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/quotes-converted/">
          <v-btn small block>% of Quotes Converted</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/jobs-financial-overview-index/">
          <v-btn small block>Jobs</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/invoiced-totals-by-date/">
          <v-btn small block>Invoiced totals by date</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/sales-statistics-summary/">
          <v-btn small block>Sales Statistics Summary</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/drs-job-type/">
          <v-btn small block>DRS Job Type Report</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm-2>
        <router-link to="/reports/aged-debt/">
          <v-btn small block>Aged Debt</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/job-pl/index">
          <v-btn small block>Job Costs</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/sales-commission">
          <v-btn small block>Sales Bonus</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/retentions">
          <v-btn small block>Retentions</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/working-time-directive">
          <v-btn small block>WTD</v-btn>
        </router-link>
      </v-col>
      <v-col sm-2>
        <router-link to="/reports/purchasing">
          <v-btn small block>Purchasing</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-link to="/reports/sage-commission">
          <v-btn small block>Sage Commission Report</v-btn>
        </router-link>
      </v-col>
      <v-col>
        <router-link to="/reports/paid-application">
          <v-btn small block>Paid Application Report</v-btn>
        </router-link>
      </v-col>
      <v-col>
        <router-link to="/reports/login-attempts">
          <v-btn small block>Login Attempts</v-btn>
        </router-link>
      </v-col>
      <v-col>
        <router-link to="/reports/chase-report">
          <v-btn small block>Chase Report</v-btn>
        </router-link>
      </v-col>
      <v-col>&nbsp;</v-col>
      <v-col>&nbsp;</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ReportsIndex',
};
</script>
